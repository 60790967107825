<template>
  <div>
    <div class="flix-form-group">
      <div class="flix-html-h2 flix-text-danger"><flixIcon :id="'bin'" /> {{ $t('message.membership') }}</div><br />
      <div class="flix-alert flix-alert-warning">{{ $t('message.allDataWillBeDeleted') }}</div>
    </div>
    <div class="flix-form-group">
      <label class="flix-html-label">{{ $t('message.password') }}</label>
      <input v-model="password" type="password" class="flix-form-control" />
    </div>
    <div class="flix-form-group" :key="password" v-if="password">
      <a @click.prevent="checkPassword()" href="#" class="flix-btn flix-btn-danger"><flixIcon :id="'bin'" /> {{ $t('message.delete') }}</a>
    </div>
    <div class="flix-form-group flix-text-danger">
      <flixIcon id="warning-sign" /> {{ $t('message.deletionPaymentInfo', { id: $getUserVariables().user.ID}) }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      code: this.getCode(),
      password: ''
    }
  },
  methods: {
    checkPassword () {
      var send = {
        user: this.$store.getters.user.md5_id,
        action: 'check_password',
        password: this.password
      }
      this.$flix_post({
        url: 'user/delete_user',
        data: send,
        callback: function (ret) {
          if (ret.data[0]) {
            this.deleteAccount(send)
          }
        }.bind(this)
      })
    },
    deleteAccount (send) {
      delete send.action
      this.$flix_post({
        url: 'user/delete_user',
        data: send,
        callback: function (ret) {
          if (ret.data[0]) {
            this.$store.commit('setLogout')
            this.$router.push({ name: 'signIn' })
          }
        }.bind(this)
      })
    },
    getCode () {
      var code = this.$store.getters.user.md5_id
      return code[code.length - 4] + '' + code[0] + '' + code[code.length - 3] + '' + code[code.length - 2] + '' + code[code.length - 1]
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
